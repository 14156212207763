import * as React from 'react';
import Header from "./content/header/Header"
import Container from './content/container/Container';
import './app.css';
import Footer from "./content/footer/Footer";
import ContactFormular from "./content/contactFormular/ContactFormular";
import { useSelector, useDispatch } from 'react-redux';
import {
  initialize, selectActive, selectReady
} from './features/rootReducer';

export default function App() {
  const active = useSelector(selectActive);
  const ready = useSelector(selectReady);
  const dispatch = useDispatch();
  if (active && !ready) {
    dispatch(initialize());
  }
  return <div className="gfhb-app gfhb-center">
    <Header />
    <Container />
    <ContactFormular />
    <Footer />
  </div>
}