import './aboutContent.css'
import AboutPic1 from "../../../images/about_pic1-1100.jpg";
import Conductance1 from "../../../icons/Conductance1";
import Conductance2 from "../../../icons/Conductance2";
import Conductance3 from "../../../icons/Conductance3";
import Conductance4 from "../../../icons/Conductance4";
export default function AboutContent() {
    return <div className="gfhb-about-content">
        <div>
            <div className="gfhb-principle-text">
                <span className="title primary">Unser Grundsatz</span>
                <div className="text primary">
                    Jeder, der Hilfe benötigt, ob für sich persönlich oder einen anderen, und an uns herantritt, soll diese möglichst zeitnah und unkompliziert erhalten.
                    <br />
                    Sei es durch die von uns angebotenen Dienstleistungen, Beratung und / oder Vermittlung.
                </div>
            </div>

            <div className="conductance white">
                <br />
                <br />
                <span className="title"> Unsere Leitwerte</span>
                <br />
                <span className="text">
                    Unsere Leitwerte orientieren sich an der deutschen Pflegecharta
                </span>
                <br />
                <div className="gfhb-principle-box-container">
                    <div className="gfhb-principle-box">
                        <div className="gfhb-principle-box-border1" />
                        <div className="gfhb-principle-box-border2" />
                        <div className="gfhb-principle-box-icon-container">
                            <Conductance1 className="gfhb-principle-box-icon1" size={65} />
                        </div>
                        <span className="title">
                            VERTRAUEN DURCH ZUVERLÄSSIGKEIT SCHAFFEN
                        </span>
                        <br />
                        <br />
                        <span className="text">
                            Die uns anvertrauten Menschen, Kunden, Angehörige, Kooperations- und Netzwerkpartner sollen in unsere zugesagten Leistungen vertrauen dürfen, daher ist es der GfHB eins der höchsten Anliegen nur Leistungen zu versprechen, welche auch unsererseits verbindlich geleistet werden können.
                        </span>
                    </div>
                    <div className="gfhb-principle-box">
                        <div className="gfhb-principle-box-border1" />
                        <div className="gfhb-principle-box-border2" />
                        <div className="gfhb-principle-box-icon-container">
                            <Conductance2 className="gfhb-principle-box-icon1" size={65} />
                        </div>
                        <span className="title">
                            RESPEKT & WÜRDE
                        </span>
                        <br />
                        <br />
                        <span className="text">
                            Menschen sind ungeachtet deren Alter, Geschlecht, Gesundheitszustand, Finanzieller Situation, Lebensweise und Herkunft Teil unserer Gesellschaft.
                        </span>
                    </div>
                    <div className="gfhb-principle-box">
                        <div className="gfhb-principle-box-border1" />
                        <div className="gfhb-principle-box-border2" />
                        <div className="gfhb-principle-box-icon-container">
                            <Conductance3 className="gfhb-principle-box-icon2" size={65} />
                        </div>
                        <span className="title">
                            SELBSTBESTIMMUNG & GESELLSCHAFT
                        </span>
                        <br />
                        <br />
                        <span className="text">
                            Jedes Mitglied sollte so weit selbstbestimmt wie es möglich ist leben und über sein eigenes Leben entscheiden dürfen und hierzu Hilfestellungen erhalten dürfen, sofern diese erwünscht sind. Jedes Mitglied unserer Gesellschaft sollte nicht isoliert von Großteil der Gesellschaft leben müssen und keine Teilhabe am Leben ermöglicht bekommen.
                        </span>
                    </div>
                    <div className="gfhb-principle-box">
                        <div className="gfhb-principle-box-border1" />
                        <div className="gfhb-principle-box-border2" />
                        <div className="gfhb-principle-box-icon-container">
                            <Conductance4 className="gfhb-principle-box-icon2" size={65} />
                        </div>
                        <span className="title">
                            TRANSPARENZ, OFFENHEIT & FAIRNESS
                        </span>
                        <br />
                        <br />
                        <span className="text">
                            Unsere Leistungen, deren Preise, unsere Werte werden offen kommuniziert. Wir behandeln alle, uns selbst, unsere Kunden, deren Angehörige, Kooperations- und Netzwerkpartner, andere Unternehmen, Organisationen, Institutionen, Vereine etc. wertschätzend, respektvoll, fair und verfolgen einen kooperativen und mit Blick auf das Wohlbefinden der Kunden gerichteten Weg.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}