import React from "react";
import Icon from "../images/zuverlaessig.svg"; // Path to your icons.svg
import PropTypes from 'prop-types';

const ReliableIcon = ({ size }) => (
    <svg className={`icon icon icon-zuverlaessig`} width={size} height={size}>
        <use xlinkHref={`${Icon}#icon-zuverlaessig`} />
    </svg>
);

ReliableIcon.propTypes = {
    size: PropTypes.number
};

export default ReliableIcon;