import { createSlice } from '@reduxjs/toolkit';
import ReactGA from "react-ga";

const initialState = {
    firstTime: true,
    active: false,
    ready: 0,
    gaID: 'UA-218765152-2' //prod: UA-218765152-2  test: UA-218765152-1
};

export const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        activate: (state) => {
            state.active = true;
            state.firstTime = false;
            if (!state.ready) {
                ReactGA.initialize(state.gaID, {
                    debug: false,
                    titleCase: false,
                    gaOptions:{
                        siteSpeedSampleRate: 100
                    }
                });
                ReactGA.pageview(window.location.pathname);
                state.ready = true;
            }
        },
        deactivate: (state) => {
            state.active = false;
            state.firstTime = false;
        },
        initialize: (state) => {
            if (state.active) {
                ReactGA.initialize(state.gaID, {
                    debug: false,
                    titleCase: false,
                    gaOptions:{
                        siteSpeedSampleRate: 100
                    }
                });
                ReactGA.pageview(window.location.pathname);
                state.ready = true;
            } else  {
                state.ready = false;
            }
        },
        reset: (state) => {
            state.active = false;
            state.firstTime = true;
        },
    },
});

export const { activate, deactivate, initialize, reset } = rootSlice.actions;
export const selectReady = (state) => state.ready;
export const selectFirstTime = (state) => state.firstTime;
export const selectActive = (state) => state.active;
export default rootSlice.reducer;
