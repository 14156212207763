import './contactFormular.css';
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import React, { useState, useCallback, useEffect } from 'react'
import {useDropzone} from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import {
    selectActive, selectReady
} from './../../features/rootReducer';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    borderRadius: '33px',
    backgroundColor: '#1f8ec7',
    textDecoration: 'none !important',
    '&:hover': {
        backgroundColor: '#003764',
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 25,
    width: 270,
    padding: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#1f8ec7',
    },
}));

const options = {
    shouldForwardProp: (prop) => prop !== 'fontColor',
};
const CssTextField = styled(TextField, options)(({ fontColor }) => ({
    input: {
        color: fontColor,
    },
    textarea: {
        color: fontColor,
    },
    "& label.Mui-focused": {
        color: "#1f8ec7"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#1f8ec7"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#003764"
        },
        "&:hover fieldset": {
            borderColor: "#1f8ec7"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1f8ec7"
        }
    }
}));

function SalutationComboBox(props) {
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={salutations}
            sx={{ width: 300 }}
            renderInput={(params) => <CssTextField {...(props.salutationValid.error ? {error:true} : {})} helperText={props.salutationValid.helperText} fontColor="#143A61" {...params} value={props.salutation} size="small" onBlur={(e) => {props.setSalutation(e.target.value); if (e.target.value.length>0) props.setSalutationV(true); }} onChange={(e) => props.setSalutation(e.target.value)} sx={{ width: '500px', padding: '10px' }} label="Anrede" variant="outlined"/>}
        />
    );
}

const salutations = [
    { label: "Herr" },
    { label: "Frau" }
];

export default function Contact() {
    const [myFiles, setMyFiles] = useState([])
    const [dialogsContent, setDialogsContent] = React.useState(null);
    const [salutation, setSalutation] = useState("");
    const [salutationV, setSalutationV] = useState(false);
    const [name, setName] = useState("");
    const [nameV, setNameV] = useState(false);
    const [email, setEmail] = useState("");
    const [emailV, setEmailV] = useState(false);
    const [phone, setPhone] = useState("");
    const [phoneV, setPhoneV] = useState(false);
    const [text, setText] = useState("");
    const [textV, setTextV] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [openErrrDialog, setErroDialogOpen] = React.useState(false);
    const [openUploadDialog, setOpenUploadDialog] = React.useState(false);
    const location = useLocation();
    const gaActive = useSelector(selectActive);
    const gaReady = useSelector(selectReady);
    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles])
    }, [myFiles])

    const fileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/rtf',
        'text/csv',
        'image/tiff',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
    ];

    const uploadValidator = function(file) {
        if (myFiles.filter(
            value => { return value.path == file.path }).length > 0) {
            var text = 'Die Datei '+file.path+ ' ist bereits in der Liste vorhanden.';
            displayWarning(text);
            return {
                code: "file-exists",
                message: `Datei ${file.path} ist bereits in der Liste enthalten.`
            };
        }
        if (myFiles.length >= 10) {
            displayWarning("Maximale Anzahl an Dateien erreicht.");
            return {
                code: "max-files",
                message: `Maximale Anzahl an Dateien erreicht.`
            };
        }
        return null;
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        validator: uploadValidator,
        accept: fileTypes.join(','),
        maxFiles: 10,
        onDropRejected: function(rejection){
            if (rejection.some(r => r.errors.some(e => e.code == 'too-many-files'))) {
                displayWarning("Gewählte Dateien überschreiten maximal zugelassene Anzahl von 10.");
                return;
            }
            rejection.forEach(function(r){
                if (r.errors.some(e => e.code == 'file-invalid-type')) {
                    displayWarning("Diese Dateityp wird nicht unterstützt. ("+r.file.path+")");

                }
            });

        }
    });

    const displayWarning = function(warningText) {
        setDialogsContent(<Alert className="upload-duplicate-warning" severity="warning">
                <span>
                    {warningText}
                </span>
            <div>
                <Button onClick={handleCloseErrorDialog}>
                    OK
                </Button>
            </div>
        </Alert>);
        handleErrorDialogOpen();
    }

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const removeAll = () => {
        setMyFiles([])
    }

    const sizeMB = (myFiles.map(item => item.size)
        .reduce((prev, curr) => prev + curr, 0)/1024/1024).toFixed(2);

    const files = myFiles.map(file => (
        <li key={file.path}>
            <DeleteIcon onClick={removeFile(file)} sx={{ color: '#fff'}} />
            {(file.size/1024/1024).toFixed(2)} MB{" "} - {file.path}
        </li>
    ));

    function submitMail(event) {
        if (maxSizeReached()) {
            displayWarning("Sie können maximal 80 MB hochladen. Durch das klicken auf den Papierkorb können Sie Dateien entfernen.");
            return;
        }
        if (allInputsValid()) {
            const data = new FormData();
            data.append("mail", "{\"title\":\""+salutation+"\",\"email\":\""+email+"\",\"name\":\""+name+"\",\"phone\":\""+phone+"\",\"text\":\""+text+"\",\"files\":[]}");
            myFiles.forEach(file =>
                data.append('mailFiles', file, file.path)
            );
            setUploadProgress(0);
            handleDialogUploadOpen();
            let config = {
                onUploadProgress: function(progressEvent) {
                    let progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    setUploadProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
                },
                headers: {
                    "Access-Control-Allow-Origin": "*"
                },
                crossDomain: true
            };

            const axiosReq = axios.post('https://be.gfhb.eu/MailSender/Mail', data, config)
                .then(function (res) {
                    console.log(res);
                    var error = null;
                    if (res.status!=200) {
                        error = "Es hätte nicht passieren dürfen. Bitte versuchen Sie es später nocheinmal."
                    } else if (!res.data.success) {
                        error = res.data.errorMessages.join('<br />');
                    }
                    if (error == null) {
                        setUploadProgress(100);
                        if (gaActive && gaReady) {
                            ReactGA.event({
                                category: 'User',
                                action: myFiles.length > 0 ? 'contact/formular-with-files' : 'contact/formular'
                            });
                        }
                        removeAll();
                        setName("");
                        setNameV(false);
                        setEmail("");
                        setEmailV(false);
                        setPhone("");
                        setPhoneV(false);
                        setText("");
                        setTextV(false);
                        setSalutation("");
                        setSalutationV(false);
                        setTimeout(function() {
                            handleCloseUploadDialog();

                        }, 500);
                    } else {
                        handleCloseUploadDialog();
                        displayWarning(error);
                    }
                })
                .catch(function (err) {
                    handleCloseUploadDialog();
                    displayWarning("Es hätte nicht passieren dürfen. Bitte versuchen Sie es später nocheinmal.");
                });
        } else {
            if (!phoneV)
                setPhoneV(true);
            if (!emailV)
                setEmailV(true);
            if (!textV)
                setTextV(true);
            if (!salutationV)
                setSalutationV(true);
            if (!nameV)
                setNameV(true);
        }
    }

    const maxSizeReached = function() {
        if (sizeMB > 80)
            return true;
        return false;
    }

    const handleErrorDialogOpen = () => {
        setErroDialogOpen(true);
    };

    const handleCloseErrorDialog = () => {
        setErroDialogOpen(false);
    };

    const handleDialogUploadOpen = () => {
        setOpenUploadDialog(true);
    };

    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
    };

    const isEmail = function(emailAddress) {
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(emailAddress);
    }

    const extract = (str, pattern) => (str.match(pattern) || []).pop() || '';
    const phoneInput = function(value){
        setPhone(extract(value, "[0-9 +]+"));
    }

    const emailValidation = function() {
        var result = {
            valid : false,
            helperText: "",
            error: false
        };
        if (emailV) {
            if (!isEmail(email)){
                result.valid = false;
                result.helperText = "Keine gültige Email.";
                result.error = true
            } else {
                result.valid = true;
                result.helperText = "";
                result.error = false
            }
        }
        return result;
    }
    const emailValid = emailValidation();

    const nameValidation = function() {
        var result = {
            valid : false,
            helperText: "",
            error: false
        };
        if (nameV) {
            if (name.length<3){
                result.valid = false;
                result.helperText = "Zu kurz";
                result.error = true
            } else {
                result.valid = true;
                result.helperText = "";
                result.error = false
            }
        }
        return result;
    }
    const nameValid = nameValidation();

    const phoneValidation = function() {
        var result = {
            valid : false,
            helperText: "",
            error: false
        };
        if (phoneV) {
            if (phone.length<4){
                result.valid = false;
                result.helperText = "Zu kurz";
                result.error = true
            }
            else {
                result.valid = true;
                result.helperText = "";
                result.error = false
            }
        }
        return result;
    }
    const phoneValid = phoneValidation();

    const textValidation = function() {
        var result = {
            valid : false,
            helperText: "",
            error: false
        };
        if (textV) {
            if (text.length<15){
                result.valid = false;
                result.helperText = "Zu kurz";
                result.error = true
            }
            else {
                result.valid = true;
                result.helperText = "";
                result.error = false
            }
        }
        return result;
    }
    const textValid = textValidation();

    const salutationValidation = function() {
        var result = {
            valid : false,
            helperText: "",
            error: false
        };
        if (salutationV) {
            if (salutation.length<1){
                result.valid = false;
                result.helperText = "Wählen Sie eine Anrede";
                result.error = true
            }
            else {
                result.valid = true;
                result.helperText = "";
                result.error = false
            }
        }
        return result;
    }
    const salutationValid = salutationValidation();

    const allInputsValid = function() {
        return salutationValid.valid && textValid.valid && phoneValid.valid && nameValid.valid && emailValid.valid;
    }

    return <div className="gfhb-contact-container">
        <div className="gfhb-contact-text">
            <span className="title primary">Kontaktieren Sie uns</span>
            <div className="text primary">
                Senden Sie uns Ihre Anfrage, wir setzen uns schnellstmöglich  mit Ihnen in Verbindung.
            </div>
        </div>
        <div className="gfhb-contact-formular">
            <div className="gfhb-input-container-area">
                <SalutationComboBox setSalutation={setSalutation} salutation={salutation} setSalutationV={setSalutationV} salutationValid={salutationValid} />
            </div>
            <div className="gfhb-input-container-area">
                <CssTextField {...(nameValid.error ? {error:true} : {})} helperText={nameValid.helperText} fontColor="#143A61" size="small" sx={{ width: '500px', padding: '10px' }} label="Vor- und Nachname" value={name} onBlur={(e) => { if (e.target.value.length>0) setNameV(true);}} onChange={(e) => setName(e.target.value)} inputProps={{ maxLength: 100 }}/>
            </div>
            <div className="gfhb-input-container-area">
                <CssTextField {...(phoneValid.error ? {error:true} : {})} helperText={phoneValid.helperText} fontColor="#143A61" size="small" sx={{ width: '500px', padding: '10px' }} label="Telefon" value={phone} onBlur={(e) => { if (e.target.value.length>0) setPhoneV(true);}} onChange={(e) => phoneInput(e.target.value)}  inputProps={{ maxLength: 3500 }}/>
            </div>
            <div className="gfhb-input-container-area">
                <CssTextField {...(emailValid.error ? {error:true} : {})} helperText={emailValid.helperText} fontColor="#143A61" size="small" sx={{ width: '500px', padding: '10px' }} label="Email" value={email} onBlur={(e) => { if (e.target.value.length>0) setEmailV(true);}} onChange={(e) => setEmail(e.target.value)} inputProps={{ maxLength: 50 }}/>
            </div>
            <CssTextField {...(textValid.error ? {error:true} : {})} helperText={textValid.helperText} fontColor="#143A61" size="small" sx={{ width: '1100px', padding: '10px' }} label="Ihre Mitteilung *" value={text} onBlur={(e) => { if (e.target.value.length>0) setTextV(true);}} onChange={(e) => setText(e.target.value)} multiline minRows={10} maxRows={20} inputProps={{ maxLength: 3500 }}/>
            {location.pathname.startsWith("/jobs") || myFiles.length > 0 ?
                <div className="gfhb-contact-file-upload-container">
                    <div className="file-upload-zone">
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>Bitte legen Sie Ihre Bewerbungsunterlagen hier ab.</p>
                        </div>
                        <aside>
                            <h4>Dateien</h4>
                            <ul>{files}</ul>
                        </aside>
                        { sizeMB > 60 ? <span className={maxSizeReached() ? 'max-size-disabled': ''}>Speicherplatz gesamt belegt: {sizeMB} MB / 80 MB</span> : null }
                        { sizeMB > 40 && myFiles.length > 7 ? <br /> : null }
                        { myFiles.length > 6 ? <span>Anzahl Dateien: {myFiles.length} / 10</span> : null }
                    </div>
                </div> : null }
            <div className="gfhb-contact-privacy-text">
                <span style={{ paddingLeft: '10px', color: '#1f8ec7' }}>Datenschutzerklärung*</span>
                <span style={{ paddingLeft: '10px', paddingTop: '10px' }}>Hiermit akzeptiere ich die von mir zu Kenntnis genommene Datenschutzerklärung. Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.</span>
            </div>
            <div className="gfhb-contact-button-container">
                <ColorButton onClick={submitMail} sx={{ width: '200px' }}>ABSCHICKEN</ColorButton>
            </div>
        </div>
        <Dialog
            open={openErrrDialog}
            onClose={handleCloseErrorDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {dialogsContent}
        </Dialog>
        <Dialog
            open={openUploadDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className="upload-message">
                Übertrage Daten an Server:
                <BorderLinearProgress variant="determinate" value={uploadProgress} />
            </div>
        </Dialog>
    </div>
}
