import './container.css'
import * as React from "react";
import {
    Routes,
    Route
} from "react-router-dom";
import Home from '../home/Home';
import Services from '../services/Services';
import About from '../about/About';
import FAQ from '../faq/Faq';
import Jobs from '../jobs/Jobs';
import Partner from '../partner/Partner';
import Contact from '../contact/Contact';
import Impress from "../impress/Imress";
import Cou from "../cou/Cou";
import Privacy from "../../privacy/Privacy";
import CookieConsent from "../cookieConsent/CookieConsent";

export default function Container() {
    return <div className="gfhb-container">
        <Routes>
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/partner" element={false ?<Partner /> : <div />} /> :
            <Route path="/contact" element={<Contact />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/impress" element={<Impress />} />
            <Route path="/cou" element={<Cou />} />
            <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <CookieConsent />
    </div>
}