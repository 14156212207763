import * as React from 'react';
import './faqContent.css'
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(90deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function CollapseContainer(props){
    const [isChecked, setIsChecked] = React.useState(false);
    const [sColor, setColor] = React.useState('#003764');

    const handleExpandClick = () => {
        setIsChecked(!isChecked);
    };

    React.useEffect(() => {
        setColor(isChecked ? '#1f8ec7' : '#003764');
    }, [isChecked]);

    return <div style={{ display: 'block' }}>
        <div style={{width: '100%', backgroundColor: sColor, color: 'white' }}>
            <ExpandMore
                expand={isChecked}
                onClick={handleExpandClick}
                aria-expanded={isChecked}
                aria-label="show more"
            >
                <div className="gfhb-icon-circle">
                    <ArrowForwardIosIcon sx={{ color: sColor, fontSize: 17 }}/>
                </div>
            </ExpandMore>
            <span onClick={handleExpandClick}>{props.title}</span>
        </div>
        <div style={{ display: 'flex', paddingTop: '7px', paddingBottom: '7px', paddingLeft: '40px', paddingRight: '40px' }}>
            <Collapse in={isChecked}>
                {props.children}
            </Collapse>
        </div>
    </div>
}

export default function FaqContent() {
    const navigate = useNavigate();

    function handleServicesClick() {
        navigate("/services");
    }

    return <div className="gfhb-faq-content" style={{ paddingTop: '50px'}}>
        <CollapseContainer title="Was für Leistungen bieten Sie an?">
            Wir bieten ambulante Haushaltshilfe, ambulante Betreuungsleistungen sowie Beratungsangebote für Menschen aller Altersgruppen an.
            Für nähere Informationen klicken Sie bitte hier → <Link onClick={handleServicesClick}>"Unsere Leistungen"</Link> </CollapseContainer>
        <CollapseContainer title="Sind Ihre Mitarbeiter geschult?">
            Jeder unserer Mitarbeiter wird vor Aufnahme seiner Tätigkeit in unserem Hause geschult, hierbei erfüllen unseren hausinternen Schulungen nicht nur die Mindestvorgaben nach § 8 AnFöVO (Anerkennung- und Förderungsverordnung) und § 45 b SGB XI, sondern beinhalteten darüber hinausgehende Inhalte, wie unter anderem Hygiene und Arbeitsschutz, um unsere Mitarbeiter bestmöglich auf Ihre Arbeit vorzubereiten.
        </CollapseContainer>
        <CollapseContainer title="Wie stellen Sie die Qualität Ihrer Leistungen sicher?">
            Durch regelmäßige Kontakte mit unseren Mitarbeitern und Kunden werden gezielte Fragen bzgl. der Versorgung durch unsere Leistungen gestellt.
            Unsere persönlichen Kundenbesuche werden angeboten, ermöglicht und sind stets erwünscht, um Gutes wie auch weniger Gutes auch in einer vertrauensvollen Umgebung ansprechen zu können.
            Darüber hinaus kontaktieren wir unsere Kunden immer nach dem Beginn unserer Leistungen sowie regelmäßig, um die Zufriedenheit mit unseren Leistungen zu erfragen, Verbesserungswünsche entgegen zu nehmen und Fragen Zeit und Raum zu geben.
            Durch die angebotenen Pflegeberatungsbesuche erfolgt, sofern gewünscht ein regelmäßiger Kontakt, welchen wir ebenfalls nutzen um die Qualität und Zufriedenheit nochmals abzufragen.
            <br />
            <br />
            Im Rahmen unseres Beschwerdemanagements werden Beschwerden protokolliert, Problemlösungsvorschläge gesucht, besprochen und dementsprechend umgesetzt.
            Kunden, Angehörige, Netzwerkpartner und Kunden haben stets die Möglichkeit Beschwerden telefonisch, persönlich oder auch per mail an:&nbsp;
            <Link href="mailto:beschwerdemanagement@gfhb.eu">beschwerdemanagement@gfhb.eu</Link>&nbsp;
            vorzutragen.
        </CollapseContainer>
        <CollapseContainer title="Was kosten Ihre Leistungen, habe ich evtl. Anspruch auf Ihre Leistungen?">
            Unsere angebotenen Leistungen der Haushaltshilfe sowie der Betreuungsleistungen kosten 35,70 € die Stunde.
            <br />
            Die Stunde gilt hierbei als volle Zeitstunde, sprich volle 60 Zeitminuten.
            <br />
            <br />
            Sie können unsere Leistungen sowohl über evtl. bestehende Ansprüche aus Leistungen der Pflegeversicherung finanzieren als auch privat.
            <br />
            Preisliche Unterschiede existieren hierbei nicht, bei Privatpersonen sind wir jedoch ohne eine anerkannte Pflege- und Betreuungsbedürftigkeit verpflichtet, die 19 % Umsatzsteuer auf unsere Leistungen zu erheben, diese rechnen wir jedoch in unseren Stundenpreis dann mit ein.
        </CollapseContainer>
        <CollapseContainer title="Woher erfahre ich, ob ich denn evtl. vorliegende Ansprüche bei z.B. meiner Pflegeversicherung habe?">
            Rufen Sie uns einfach an, wenn bereits ein Pflegegrad vorliegt, die regulären Leistungen sind uns durchaus bekannt.
            <br />
            <br />
            Ab anerkannten Pflegegrad 1 haben Sie Anspruch auf monatliche Entlastungsleistungen nach § 45b SGB XI sowie auf einen Pflegeberatungsbesuch halbjährlich.
            Gerne können wir Ihnen auch die möglichen Leistungen der Pflegeversicherung bei einem Beratungsbesuch erläutern.
        </CollapseContainer>
        <CollapseContainer title="Was passiert mit den Entlastungsleistungen nach § 45 b SGB XI der Pflegeversicherung, welche nicht genutzt worden sind?">
            Ab dem Zeitpunkt der Anerkennung des Pflegegrades haben Sie Anspruch auf die Entlastungsleistungen.
            In den Monaten, in denen diese nicht genutzt worden sind, verfallen diese Leistungen nicht sofort, sondern
            summieren sich zu einem Entlastungsbudget.
            <br />
            <br />
            Dieses bisher nicht genutzte Budget eines Kalenderjahres können Sie regulär bis zum 30.06. des Folgejahres nutzen.
        </CollapseContainer>
        <CollapseContainer title="Wie läuft die Abrechnung, erhalte ich eine Rechnung?">
            Die Abrechnung der von uns genutzten Leistungen erfolgt monatsweise, und dies nach Abschluss der
            Leistungen des jeweiligen Monats.
            <br />
            <br />
            Der jeweilige Mitarbeiter bei Ihnen vor Ort, dokumentiert die erbrachten Leistungen über einen
            Leistungsnachweis, welcher seitens des Kunden nach dem letzten Einsatz des jeweiligen Monats unterschrieben wird.
            Anhand des Leistungsnachweises erfolgt die Abrechnung.
            <br />
            <br />
            Ob Sie eine Rechnung erhalten kommt natürlich darauf an, inwiefern es Ihr Wunsch ist und wer die Kosten für
            diese Leistungen tragen soll. Wenn Sie mindestens den Pflegegrad 1 haben, so haben wir auch die Möglichkeit
            mittels einer Abtretungserklärung direkt mit der gesetzlichen Pflegeversicherung über den Entlastungsbetrag
            nach § 45 b SGB XI abzurechnen. Ab Pflegegrad 2 könnten Sie auch evtl. Anspruch auf Verhinderungspflege nach
            § 39 SGB XI haben, gerne können wir Ihr gemäß Ihrem Wunsch auch dann direkt mit der gesetzlichen
            Pflegeversicherung abrechnen.
            <br />
            <br />
            Im Sinne der Nachhaltigkeit versenden wir nicht automatisch Kopien von Abrechnungen an unsere Kunden,
            sondern erst auf Anfrage und Kundenwunsch.
            <br />
            <br />
            Sind Sie privat versichert kriegen Sie wie anderweitig gewohnt die Rechnung zugesendet, diese können Sie
            dann an ihre private Pflegeversicherung weiterleiten.
        </CollapseContainer>
        <CollapseContainer title="Berechnen Sie  Anfahrts- und / oder Abfahrtspauschalen, Kosten für gefahrene
         Kilometer und ähnliches?">
            Für die An- und Abfahrt unserer Haushaltshelfer / Alltagsbegleiter zu Ihnen nach Hause müssen Sie nichts
            bezahlen.
            <br />
            <br />
            Wenn im Rahmen der von Ihnen gebuchten Einsätze Fahrten notwendig werden, wie z.B. wenn ihr Haushaltshelfer
            / Alltagsbegleiter für Sie Einkaufen fahren soll, so erheben wie Fahrtkosten von je 0,30 € für
            jeden gefahrenen Kilometer.
            <br />
            <br />
            Hierzu bekommen Sie gesondert und privat eine Auflistung und Abrechnung.
        </CollapseContainer>
        <CollapseContainer title="Gehe ich mit Ihnen einen Vertrag ein, gibt es eine Mindestlaufzeit, eine
         Kündigungsfrist oder dergleichen?">
            Wir schließen mit Ihnen als Kunde eine Dienstleistungsvereinbarung ab, die unter anderem Punkte wie Haftung
            bei Schadensfällen, Umgang mit Ihren Daten, Vertretungsregelungen, die gewünschten Leistungen festlegt.
            <br />
            <br />
            Wir sind transparent und fair mit unseren Leistungen sowie den dazugehörigen Preisen.
            <br />
            <br />
            Wir arbeiten prinzipiell mit Ihnen fest zugeteilten Mitarbeitern sowie vorzugsweise festen Zeitplänen.
            <br />
            <br />
            Eine Mindestlaufzeit sowie eine Kündigungsfrist bestehen bei uns nicht, Sie haben jederzeit das Recht und
            die Möglichkeit die Leistungserbringung unsererseits einstellen zu lassen.
        </CollapseContainer>
        <CollapseContainer title="Gibt es Präventionsmaßnahmen, besondere Schutzvorkehrungen bzgl. Corona?">
            Jeder unserer Mitarbeiter wird geschult sowie mit entsprechendem Equipment ausgestattet.
            <br />
            Die Mitarbeiter tragen während der Einsätze stets eine frische FFP-2-Maske und führen mindestens eine
            hygienische Händedesinfektion vor und nach dem Einsatz durch.
            <br />
            Es wird auch auf die Einhaltung des Mindestabstandes Acht gegeben.
            <br />
            Zudem sprechen wir uns ganz klar für das Impfen aus, begrüßen Informations- sowie Impfaktionen als solche.
        </CollapseContainer>
        <CollapseContainer title="Was kostet die Beratung, was umfasst diese und was ist hierbei zu beachten?">
            Unsere Beratungsangebote sind unterschiedlich.
            <br />
            Zum einem gibt es für jeden Kunden ein Aufnahmegespräch, welches absolut unverbindlich und kostenlos ist.
            <br />
            Zum anderen gibt es weitere Beratungsangebote.
            <br />
            Der häufigste Beratungsanlass ist der
            <ul>
                <li>Pflegeberatungsbesuch / der Pflegeberatungseinsatz nach § 37 Abs. 3 SGB XI</li>
                <li>Hierbei trägt im Regelfall Ihre Pflegeversicherung die Kosten.</li>
                <li>Hier steht der Mensch mit anerkannten Pflegegrad, mit seinen persönlichen Fähigkeiten und
                    Einschränkungen im Mittelpunkt.</li>
                <li>Sinnvoll ist hierbei immer, sofern vorhanden, dass eine Pflegeperson, wie z.B. ein pflegender
                    Angehöriger mit anwesend ist.</li>
                <li>Hierbei wird geschaut, ob die häusliche Versorgung im Bereich der Pflege und Betreuung gesichert
                    sind, ob noch Verbesserungsvorschläge, Tipps, Ratschläge gegeben werden können um z.B.
                    pflegerelevante Risiken, Defizite abmildern zu können aber auch Fähigkeiten fördern und erhalten
                    zu können.</li>
                <li>Hierbei wird auch überprüft, ob Leistungen evtl. noch abgerufen werden können, diese werden bei
                    Bedarf empfohlen.</li>
                <li>Es wird auch angeboten, dass überprüft wird, ob das häusliche Umfeld durch z.B. den Einsatz von
                    technischen Hilfsmitteln in Bezug auf die Pflegesituation aufgewertet werden kann, ob anderweitige
                    Hilfsmittel, Beratungsangebote oder Leistungen empfohlen werden können.</li>
                <li>Im Rahmen des Beratungsbesuches wird auch Zeit und Raum für Ihre Fragen, Sorgen, Bedürfnisse
                    etc. gegeben.</li>
            </ul>
            <br />
            Diese Beratungsbesuche sind verpflichtend für
            <ul>
                <li>Personen mit Pflegegrad 2 bis 3, und dies halbjährlich</li>
                <li>Personen mit Pflegegrad 4 bis 5, und dies vierteljährlich</li>
                <li>Die Kosten hierfür trägt Ihre Pflegeversicherung.</li>
                <li>Einen Pflegeberatungsbesuch können Sie jedoch auch bereits freiwillig ab Pflegegrad halbjährlich
                    wahrnehmen.</li>
            </ul>
            <br />
            Auch hierfür trägt die Kosten Ihre Pflegeversicherung.
        </CollapseContainer>
    </div>
}