import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import {
    selectActive, selectReady
} from './../../../features/rootReducer';

import './servicesContent.css'
import ReactGA from "react-ga";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: '#143A61',
        backgroundColor: '#fff',
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#143A61'
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#000',
        },
    }),
);


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabLabelElement(props) {
    return <div className="gfhb-services-tab-box" style={{ width: '259px', height: '200px' }}>
        <div style={{ padding: '10px' }}>
            {props.title}
        </div>
        <br />
        <div style={{ padding: '10px' }}>
            {props.text}
        </div>
    </div>
}

function SericeListElement(props) {
    return <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '7px' }}>
        <CheckIcon sx={{ color: 'green' }} />
        {props.children}
        <br />
    </span>
}

export default function ServicesContent() {
    const [value, setValue] = React.useState(0);
    const gaActive = useSelector(selectActive);
    const gaReady = useSelector(selectReady);
    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 1:
                if (gaActive && gaReady) {
                    ReactGA.modalview("services/haushaltshilfe");
                }
                break;
            case 2:
                if (gaActive && gaReady) {
                    ReactGA.modalview("services/betreuung");
                }
                break;
            case 3:
                if (gaActive && gaReady) {
                    ReactGA.modalview("services/beratung");
                }
                break;
            case 4:
                if (gaActive && gaReady) {
                    ReactGA.modalview("services/asssistenz");
                }
                break;
            default:
                if (gaActive && gaReady) {
                    ReactGA.modalview("services/haushaltshilfe");
                }
        }
        setValue(newValue);
    };

    return <div className="gfhb-services-content">
        <div className="gfhb-principle-text">
            <span className="title primary">Unsere Leistungen</span>
            <div className="text primary">
                Wir bieten Ihnen eine Vielzahl an unterschiedlichen Leistungen an.
            </div>
        </div>
        <div className="gfhb-services-tabs-container">
            <Box sx={{ width: '100%' }}>
                <Box sx={{ bgcolor: '#fff' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <StyledTab label={<TabLabelElement title="HAUSHALTSHILFE" text="Wir übernehmen verschiedene Aufgaben im Haushalt."/>} {...a11yProps(0)} />
                        <StyledTab label={<TabLabelElement title="BETREUUNG" text="Wir bieten verschiedene und individuelle Betreuungstätigkeiten an."/>} {...a11yProps(1)} />
                        <StyledTab label={<TabLabelElement title="BERATUNG" text="Wir beraten Sie rund um das Thema häusliche Pflege."/>} {...a11yProps(2)} />
                        <StyledTab label={<TabLabelElement title="ASSISTENZ" text="Kostenlose Hilfe zu weiteren Dienstleistungen"/>} {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <div className="gfhb-services-tabs-content">
                    <TabPanel value={value} index={0}>
                        <SericeListElement>
                            Reinigen der Wohnung, des nächsten häuslichen Umfelds, hierfür fällt das Reinigen von Oberflächen wie Böden, Regalen und Ablagen
                        </SericeListElement>
                        <SericeListElement>
                            Müll rausbringen
                        </SericeListElement>
                        <SericeListElement>
                            Pflanzen bewässern
                        </SericeListElement>
                        <SericeListElement>
                            Mahlzeiten zubereiten
                        </SericeListElement>
                        <SericeListElement>
                            Wäsche waschen, trocknen, aufhängen, bügeln, zusammenlegen, verstauen
                        </SericeListElement>
                        <SericeListElement>
                            Einkaufen, Einkauf verräumen
                        </SericeListElement>
                        <SericeListElement>
                            Fenster reinigen
                        </SericeListElement>
                        <SericeListElement>
                            Reinigen von Bad & Küche, sonstige Räumen
                        </SericeListElement>
                        <SericeListElement>
                            Betten beziehen, richten, Gardinen abhängen, waschen und anhängen
                        </SericeListElement>
                        <SericeListElement>
                            Staubsaugen und wischen
                        </SericeListElement>
                        <SericeListElement>
                            Hausflur reinigen
                        </SericeListElement>
                        <SericeListElement>
                            Lüften der Räumlichkeiten
                        </SericeListElement>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <SericeListElement>
                            Unterhaltungen
                        </SericeListElement>
                        <SericeListElement>
                            Spiele spielen wie Rummikub, Karten, Schach, Mensch ärgere dich nicht
                        </SericeListElement>
                        <SericeListElement>
                            Kniffeln, Quizspiele, Rätselspiele
                        </SericeListElement>
                        <SericeListElement>
                            Ausflüge
                        </SericeListElement>
                        <SericeListElement>
                            Spaziergänge
                        </SericeListElement>
                        <SericeListElement>
                            Begleitungen zu Terminen wie z.B. zum Arzt, zum Friseur, zur Physiotherapie, zur Schule, zum Sport
                        </SericeListElement>
                        <SericeListElement>
                            Beaufsichtigung von Kindern
                        </SericeListElement>
                        <SericeListElement>
                            weitere Betreuungsaktivitäten
                        </SericeListElement>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Rund um das Thema der häuslichen Pflege, Betreuung, Leistungen der Pflegeversicherung. <br />
                        <br />
                        Hierunter fallen auch die Pflegeberatungsbesuche nach § 37 Abs. 3 SGB XI, welche je nach Pflegegrad und ausschließlicher Nutzung des Pflegegeldes verpflichtend sind.
                        <br />
                        <br />
                        Gerne kommen wir hierzu zu Ihnen und beraten Sie bei einem Besuch, den Nachweis hierüber übermitteln wir spätestens am Folgetag an Ihre Pflegeversicherung.
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <SericeListElement>
                            Gerne halten wir unsere Augen und Ohren für Sie offen, um Ihnen z.B. bei der Suche nach einem Pflegedienst behilflich zu sein.
                        </SericeListElement>
                        <SericeListElement>
                            Aufgrund unserer kooperativen Zusammenarbeit mit anderen Diensten ist es nicht ungewöhnlich, dass wir was passendes empfehlen können.
                        </SericeListElement>

                        <br /><br />Falls Sie Unterstützung suchen, aber nicht genau wissen<br /><br />
                        <SericeListElement>
                            was evtl. das richtige für Sie sein könnte
                        </SericeListElement>
                        <SericeListElement>
                            wie diese finanziert werden könnte
                        </SericeListElement>
                        <SericeListElement>
                            wie sich diese gestalten könnte etc.
                        </SericeListElement>
                        <br />
                        so können Sie gerne dennoch auf uns unverbindlich zukommen.
                    </TabPanel>
                </div>
            </Box>
        </div>
    </div>;
}