import './cookieConsent.css'
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import { Link } from "react-router-dom";
import {
    activate, deactivate,
    selectFirstTime
} from './../../features/rootReducer';

export default function CookieConsent() {
    const firstTime = useSelector(selectFirstTime);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(firstTime);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const accept = function() {
        dispatch(activate());
        setOpen(false);
    }
    const decline = function() {
        dispatch(deactivate());
        setOpen(false);
    }

    const action = (
        <div>
            <Button style={{ color: '#1f8ec7' }}  size="small" onClick={accept}>
                Zustimmen
            </Button>
            <Button style={{ color: '#1f8ec7' }} size="small" onClick={decline}>
                Ablehnen
            </Button>
        </div>

    );

    return <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={60000}
        onClose={handleClose}
    ><Alert style={{ color: '#003764' }} severity="warning" action={action}>Diese Seite verwendet Cookies und Google Analytics (<Link to="/privacy">Datenschutz</Link>)</Alert></Snackbar>
}