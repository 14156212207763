import './jobsContent.css';
import JobsIcon from "../../../icons/JobsIcon";
import * as React from "react";
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import ReactGA from 'react-ga';

export default function JobsContent() {

    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen1 = () => {
        ReactGA.modalview("/jobs/haushaltsferin");
        setOpen1(true);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleClickOpen2 = () => {
        setOpen2(true);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };

    return <div className="gfhb-jobs-container">
        <div className="gfhb-jobs-text">
            <span className="title primary">Offene Stellen</span>
            <div className="text primary">
                Wir suchen Verstärkung für unser Team.
                <br />
                Sie werden gebraucht.
            </div>
        </div>
        <br />
        <div className="gfhb-jobs-boxes-container">
            <div className="gfhb-job-box">
                <div className="gfhb-job-box-border1" />
                <div className="gfhb-job-box-border2" />

                <div className="gfhb-job-box-icon-container">
                    <JobsIcon className="gfhb-job-icon" size={65}/>
                </div>
                <span className="title primary">
                    Haushaltshelfer/in
                </span>
                <br />
                <br />
                <span className="text primary">
                    Haushaustshelfer / Alltagsbegleiter
                    <br />
                    (m/w/d)
                    <br />
                    Auf Minijob-Basis
                </span>&nbsp;
                <Link onClick={handleClickOpen1}>Weiterlesen..</Link>
            </div>
        </div>
        <Dialog
            fullScreen={fullScreen}
            open={open1}
            maxWidth="md"
            onClose={handleClose1}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title" sx={{
                color: '#143A61'
            }}>
                {"Haushaltshelfer/in"}
            </DialogTitle>
            <DialogContent sx={{
                color: '#143A61'
            }}>
                <div>
                    Sie werden gebraucht!
                    <br />
                    Als Helfer im Haushalt & Begleiter im Alltag (m/w/d)
                    <br />
                    Auf Minijobbasis
                    <br />
                    Für Dortmund und Umgebung.
                    <br />
                    <br />
                    Wir, die GfHB UG sind ein junges und im August 2021 gegründetes Unternehmen mit Sitz in Dortmund, wir haben uns auf Angebote zur Unterstützung im Alltag spezialisiert und bieten insbesondere Leistungen der
                    <ul>
                        <li>ambulanten Haushaltshilfe</li>
                        <li>ambulanten Betreuungsleistungen sowie</li>
                        <li>Beratungen rund um das Thema der häuslichen Pflege und Betreuung an</li>
                    </ul>
                    und suchen zum nächstmöglichen Termin, nach vorheriger Schulung, Mitarbeiter als
                    Haushaltshelfer & Alltagsbegleiter.
                    <br /><br />
                    Quereinsteiger sind willkommen, das was zählt sind Sie als Mensch.
                    <br /><br />
                    Die Bereitschaft, Haushaltsnahe Dienstleistungen zu verrichten sowie grundlegende Kommunikation mit unseren Kunden aufrecht zu erhalten sind zwingende Voraussetzungen.
                    <br /><br />
                    Voraussetzung für die Tätigkeit ist eine Basisqualifizierung nach § 8 AnFöVo und § 45 b SGB XI, diese Basisqualifizierung werden Sie bei uns im Hause durchlaufen.
                    <br />
                    <br />
                    <b>WIR BIETEN IHNEN:</b>
                    <ul>
                        <li>
                            Ehrlichkeit, Transparenz, Fairness und Respekt
                        </li>
                        <li>
                            Direkter Einstieg ohne Vorkenntnisse, nach Abschluss der Basisqualifizierung durch die hausinterne Einstiegsschulung vor Tätigkeitsbeginn.
                        </li>
                        <li>
                            Hohe Vereinbarkeit von Familie und Beruf
                        </li>
                        <li>
                            Flexible Arbeitszeiten, indem Sie uns mitteilen, von wann bis wann Sie arbeiten können und wollen
                        </li>
                        <li>
                            Faire Entlohnung
                        </li>
                        <li>
                            Einsatzorte sind die Haushalte und die häusliche Umgebung des jeweiligen Kunden, freuen Sie sich darauf, mindestens 2 Stunden im selben Haushalt tätig zu sein
                        </li>
                        <li>
                            Bezahlte Fahrtzeiten
                        </li>
                    </ul>
                    <br />
                    Art der Stelle: Mini-Job
                    <br />
                    <br />
                    <b>IHR PROFIL:</b>
                    <ul>
                        <li>
                            Sie mögen den fürsorglichen und respektvollen Umgang mit Senioren und anderen hilfe- und / oder pflegebedürftigen Menschen.
                        </li>
                        <li>
                            Sie können und wollen mit anpacken, wenn es ein Kunde nicht mehr allein schafft oder Unterstützung benötigt.
                        </li>
                        <li>
                            Sie leisten gerne Unterstützung, sei es im Haushalt, beim Einkauf oder einfach in Form von Unterhaltungen und Begleitungen.
                        </li>
                        <li>
                            Sie arbeiten lieber vernünftig und qualitätsgerecht als vorschnell und hektisch.
                        </li>
                        <li>
                            Wenn Sie sich eine Beschäftigung mit Sinn wünschen, dann sind Sie bei uns richtig, dies drückt sich bei uns im Dank und im Vertrauen unserer Kunden aus.
                        </li>
                        <li>
                            Sie verfügen über gute Deutschkenntnisse in Wort und Schrift, um Unterhaltungen führen zu können sowie unsere Leistungen zu dokumentieren.
                        </li>
                        <li>
                            PKW ist von Vorteil, jedoch keine Voraussetzung.
                        </li>
                    </ul>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose1} autoFocus>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            fullScreen={fullScreen}
            open={open2}
            onClose={handleClose2}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"Alltagsbegleiter/in"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    WO IST DER TEXT?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose2} autoFocus>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}