import React from "react";
import Icon from "../images/beratung.svg"; // Path to your icons.svg
import PropTypes from 'prop-types';

const ConsultationIcon = ({ name, color, size }) => (
    <svg className={`icon icon-beratung`} width={size} height={size}>
        <use xlinkHref={`${Icon}#icon-beratung`} />
    </svg>
);

ConsultationIcon.propTypes = {
    size: PropTypes.number
};

export default ConsultationIcon;