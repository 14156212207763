import  { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const imgCache = {
    __cache: {},
    read(src) {
        if (!this.__cache[src]) {
            this.__cache[src] = new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    this.__cache[src] = true;
                    resolve(this.__cache[src]);
                };
                img.src = src;
            }).then((img) => {
                this.__cache[src] = true;
            });
        }
        if (this.__cache[src] instanceof Promise) {
            throw this.__cache[src];
        }
        return this.__cache[src];
    }
};

let globalSetCache = () => { throw new Error('you must useImgCache before setting its state'); };

export const useImgCache = singletonHook(imgCache, () => {
    const [cache, setCache] = useState(imgCache);
    globalSetCache = setCache;
    return cache;
});

export const setCache = cache => globalSetCache(cache);