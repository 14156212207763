import React from "react";
import Icon from "../images/leitwerte1.svg"; // Path to your icons.svg
import PropTypes from 'prop-types';

const Conductance1 = ({ className, size }) => (
    <svg className={`icon icon-leitwert1 `+className} width={size} height={size}>
        <use xlinkHref={`${Icon}#icon-leitwert1`} />
    </svg>
);

Conductance1.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number
};

export default Conductance1;