import * as React from 'react';
import { useLocation, Link } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import './menu.css';
import MenuHeader from "./menuHeader/MenuHeader";

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            color: '#007FB0',
        },
        '&.Mui-selected': {
            color: '#007FB0',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

export default function Menu(props) {
    const location = useLocation();
    const possibleLocation = ["/about", "/services", '/faq', "/jobs", "/partner", "/contact"]; //Alle Menüpunkte
    const [value, setValue] = React.useState(possibleLocation.includes(location.pathname) ? location.pathname : false);

    React.useEffect(() => {
        if (possibleLocation.includes(location.pathname)) {
            setValue(location.pathname);
        } else {
            setValue(false);
        }
    }, [location]);

    return <div className="gfhb-menu">
        <MenuHeader />
        <StyledTabs
              variant="fullWidth"
              scrollButtons="auto"
              value={value}
              >
            <StyledTab label="Über uns" value="/about" component={Link} to={'/about'} />
            <StyledTab label="Leistungen" value="/services" component={Link} to={'/services'} />
            <StyledTab label="Fragen & Antworten" value="/faq" component={Link} to={'/faq'} />
            <StyledTab label="Jobs" value="/jobs" component={Link} to={'/jobs'} />
            {false ?<StyledTab label="Partner" value="/partner" component={Link} to={'/partner'} /> : null}
            <StyledTab label="Kontakt" value="/contact" component={Link} to={'/contact'} />
        </StyledTabs>
    </div>
}
