import './home.css'
import { Fragment, useEffect } from "react";
import HomeContent from "./homeContent/HomeContent";

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return <Fragment>
        <HomeContent />
    </Fragment>
}