import './footer.css'
import { Link } from "react-router-dom";
export default function Footer() {
    return <div className="gfhb-footer">
        <span>
            <Link to="/">© GfHB UG 2021. Alle Rechte vorbehalten</Link>
        </span>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <span>
            <Link to="/impress">Impressum</Link>
        </span>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <span>
            <Link to="/privacy">Datenschutz</Link>
        </span>
    </div>
}