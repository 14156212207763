import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import configureStore from "./store/configureStore";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/lib/integration/react";

import reportWebVitals from './reportWebVitals';
const rootElement = document.getElementById("root");

const { store, persistor } = configureStore();

render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    rootElement
);
reportWebVitals();
