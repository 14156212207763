import './menHeader.css';
import GFHBLogo from './../../../images/gfhb-white.png';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom";
import * as React from "react";

export default function MenuHeader(props) {
    return <div className="gfhb-down-blue-line gfhb-menu-header">
        <Link to="/"><img src={GFHBLogo} className="gfhb-menu-header-logo" /></Link>
        <Stack direction="row" spacing={3} alignItems="center" style={{ paddingRight: '30px'}}>
            <Stack direction="row" spacing={1}>
                <PhoneInTalkIcon sx={{ color: '#1f8ec7', fontSize: 50 }} />
                <Stack spacing={1}>
                    <span style={{ color: '#1f8ec7' }}>Rufen Sie uns an</span>
                    <span style={{ color: 'white' }}>0231 - 29 29 82 89</span>
                </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
                <EmailIcon sx={{ color: '#1f8ec7', fontSize: 50 }}/>
                <Stack spacing={1}>
                    <span style={{ color: '#1f8ec7' }}>Schreiben Sie uns</span>
                    <span style={{ color: 'white' }}>info@gfhb.eu</span>
                </Stack>
            </Stack>
        </Stack>
    </div>
}