import React from "react";
import Icon from "../images/leitwerte3.svg"; // Path to your icons.svg
import PropTypes from 'prop-types';

const Conductance3 = ({ className, size }) => (
    <svg className={`icon icon-leitwert3 `+className} width={size} height={size}>
        <use xlinkHref={`${Icon}#icon-leitwert3`} />
    </svg>
);

Conductance3.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number
};

export default Conductance3;