import React, { Suspense } from 'react';
import Menu from '../menu/Menu'
import './header.css';
import { useLocation } from 'react-router-dom';
import HomeBG from '../../images/gfhb-home-bg-1100.jpg';
import AboutBG from '../../images/gfhb-about-bg-1100.jpg';
import ContactBG from '../../images/gfhb-contact-bg-1100.jpg';
import FAQBG from '../../images/gfhb-faq-bg-1100.jpg';
import JobsBG from '../../images/gfhb-jobs-bg-1100.jpg';
import PartnerBG from '../../images/gfhb-partner-bg-1100.jpg';
import ServicesBG from '../../images/gfhb-services-bg-1100.jpg';
import { useNavigate } from "react-router-dom";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useImgCache } from './../../features/imageCache';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import {
    selectActive, selectReady
} from './../../features/rootReducer';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#007FB0',
    borderRadius: '33px',
    backgroundColor: 'white',
    textDecoration: 'none !important',
    '&:hover': {
        backgroundColor: '#d4d4d4',
    },
}));

export const SuspenseImg = ({ src, ...rest }) => {
    const images = useImgCache();
    images.read(src);
    return <img src={src} {...rest} />;
};

export const Loading = () => {
    return <div className="bg-loading" />;
};

export default function Header() {
    let location = useLocation();
    const navigate = useNavigate();
    const gaActive = useSelector(selectActive);
    const gaReady = useSelector(selectReady);
    let bgImage = HomeBG;

    function handleServicesClick() {
        navigate("/services");
    }

    function handleContactClick() {
        navigate("/contact");
    }

    let linkTo = <ColorButton onClick={handleServicesClick}>Unsere Leistungen</ColorButton>;

    if (location.pathname.startsWith("/services")) {
        linkTo = <ColorButton onClick={handleContactClick}>Kontakt</ColorButton>;
    }

    let headerContent = <div className="gfhb-header-view-content-container">
            <span className="gfhb-headerView-text-large">
                WIR HELFEN<br />
                IN HAUSHALT & BETREUUNG
            </span>
        <span className="gfhb-headerView-text-medium">-Abrechnung über die Pflegekasse möglich-</span>
        <br />
        {linkTo}
    </div>;

    switch (location.pathname) {
        case '/about':
            document.title = "GfHB | Über uns";
            bgImage = AboutBG;
            break;
        case '/contact':
            document.title = "GfHB | Kontakt";
            bgImage = ContactBG;
            headerContent = <div className="gfhb-header-view-content-container">
            <span className="gfhb-headerView-text-large">
                WIR HABEN IHR<br />
                INTERESSE GEWECKT?
            </span>
                <span className="gfhb-headerView-text-medium">Setzen Sie sich mit uns in Verbindung!</span>
                <br />
                <span className="gfhb-headerView-text-medium">Mo - Fr: 8.00 - 14.30 Uhr</span>
                <br />
                <span className="gfhb-headerView-text-large">0231 - 29 29 82 89</span>
            </div>;
            break;
        case '/faq':
            document.title = "GfHB | Fragen & Antworten";
            bgImage = FAQBG;
            break;
        case '/jobs':
            document.title = "GfHB | Stellenangebote";
            bgImage = JobsBG;
            break;
        case '/partner':
            document.title = "GfHB | Unsere Partner";
            bgImage = PartnerBG;
            break;
        case '/services':
            document.title = "GfHB | Unsere Leistungen";
            bgImage = ServicesBG;
            break;
        case '/privacy':
            document.title = "GfHB | Datenschutz";
            bgImage = HomeBG;
            break;
        case '/impress':
            document.title = "GfHB | Impressum";
            bgImage = HomeBG;
            break;
        default:
            document.title = "GfHB";
            bgImage = HomeBG;
            break;
    }

    if (gaActive && gaReady) {
        ReactGA.pageview(location.pathname);
    }

    return <div className="gfhb-header">
        <Suspense fallback={<Loading />}>
            <SuspenseImg className="gfhb-header-background" src={bgImage} alt="" fallback={<Loading />} />
        </Suspense>
        <Menu />
        {headerContent}
    </div>;
}