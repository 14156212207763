import './homeContent.css'
import HomePic1 from './../../../images/gfhb-home-pic1.jpg';
import ConsultationIcon from "../../../icons/ConsultationIcon";
import ReliableIcon from "../../../icons/ReliableIcon";
import SupportIcon from "../../../icons/SupportIcon";
import FinanceIcon from "../../../icons/FinanceIcon";
import React, {Fragment, Suspense} from "react";
import {useImgCache} from "./../../../features/imageCache";

export const SuspenseImg = ({ src, ...rest }) => {
    const images = useImgCache();
    images.read(src);
    return <img src={src} {...rest} />;
};

export const Loading = () => {
    return <div className="image1-loading" />;
};

export default function HomeContent() {
    return <Fragment>
        <div className="gfhb-home-content">
            <div style={{width: '50%'}}>
                <Suspense fallback={<Loading />}>
                    <SuspenseImg className="gfhb-header-background" src={HomePic1} alt="" fallback={<Loading />} />
                </Suspense>
            </div>
            <div style={{width: '50%'}}>
                <span className="gfhb-homeContent-text-large">Wir von der GfHB…</span>
                <br/>
                <span className="gfhb-homeContent-text-medium">…haben uns auf Angebote zur Unterstützung im Alltag spezialisiert & bieten unsere Leistungen in Dortmund und Umgebung an. Zusätzlich bieten wir Beratungen rund um das Thema der häuslichen Pflege & Betreuung an. Gerne können Sie über uns auch Beratungsbesuche wahrnehmen, selbstverständlich erfolgt auf Wunsch auch der Nachweis an Ihre Pflegeversicherung
                <br/>
                <br/>
                Jeder, der Hilfe benötigt, ob für sich persönlich oder für einen anderen, und an uns herantritt, soll diese möglichst zeitnah & unkompliziert erhalten. Sei es durch die von uns angebotenen Dienstleistungen, Beratung und / oder Vermittlung.
            </span>
            </div>
        </div>
        <br />
        <div className="gfhb-home-service">
            <span>
                <ConsultationIcon size={60}/>
                <br />
                BERATUNG <br />
                zur Pflege, Betreuung <br /> und Versicherung.
            </span>
            <span>
                <SupportIcon size={60}/>
                <br />
                UNTERSTÜTZUNG <br />
                im Alltag durch Übernehmen der <br /> Aufgaben im Haushalt.
            </span>
            <span>
                <FinanceIcon size={60}/>
                <br />
                FINANZIERUNG <br />
                Abrechnung über die Pflegekasse <br /> möglich.
            </span>
            <span>
                <ReliableIcon size={60}/>
                <br />
                ZUVERLÄSSIG <br />
                und vertrauensvoll. <br /> Transparent, offen und fair.
            </span>
        </div>
    </Fragment>

}