import './impress.css'
import Paper from '@mui/material/Paper';
export default function Impress() {
    return <div className="gfhb-impress-content">
        <div>
            <Paper sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'left', alignItems: 'left', backgroundColor: '#143A61', color:'#fff', padding: '15px', flexWrap: 'wrap' }}>
                <span>GfHB UG (haftungsbeschränkt)</span>
                <span>Londoner Bogen 3</span>
                <span>44269 Dortmund</span>
                <span>Telefon: 0231 29 29 82 89</span>
                <span>Telefax: 0231 29 29 83 93</span>
                <span>Web: www.gfhb.eu</span>
                <span>E-Mail: info@gfhb.eu</span>
            </Paper>
        </div>
        <div>
            <Paper sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'left', alignItems: 'left', backgroundColor: '#143A61', color:'#fff', padding: '15px', flexWrap: 'wrap' }}>
                <span>Geschäftsführer: Jörg Krasmik</span>
                <span>Registergericht: Amtsgericht Dortmund</span>
                <span>Registernummer: HRB 33239</span>
                <span>IK (Institutionskennzeichen): 460 568 120</span>
                <span>Steuer-Nr.: 317/5910/2518</span>
                <span>Finanzamt: Dortmund - Ost</span>
            </Paper>
        </div>
    </div>
}